/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

.agency-management {
height: calc(100vh - 64px);
width: 100%;
padding: 15px;
@include themed() {
background-color: t($background);
}

&__row {
    &--not-editable {
        opacity: 50%;
    }
    &--editable {
        opacity: 100%;
        @include themed() {
            background-color: t($background-alternate) !important;
        }
    }
}

.table-background {
    height: 100%;
    @include themed() {
        background-color: t($background-alternate);
    }
}
}

.edit-options {
display: flex;
justify-content: space-between;
flex-direction: column;
 &__save {
    &--can-save{
        color: #23BEB0;
        cursor: pointer;
        pointer-events: auto;
    }
    &--cannot-save{
        color: gray;
        pointer-events: none;
    }
    
 }
}

.new-agency-form {
    display: grid;
    gap: 10px;
    grid-template-areas:
    "agencyORI      agencyORI       distributionMethod  distributionMethod"
    "reasonFingerprinted      reasonFingerprinted      agencyName          agencyName"
    "agencyEmail    agencyEmail     agencyEmail         agencyEmail"
    "agencyAddress  agencyAddress   agencyAddress       agencyAddress"
    "agencyCity     agencyState     agencyZipCode        agencyCounty" 
}
