/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

.ant-btn-primary {
  @include themed() {

    border-color: t($primary);
  }
}

.ant-btn.button-outline {
    background-color: transparent;
    @include themed() {
      color: t($text-color);
      border: 2px solid t($primary);
    }
    &:hover {
      @include themed() {
        background-color: t($primary-transparent);
      } 
    }
  }
  .ant-btn.button-outline-resub {
    background-color: transparent;
    position: fixed;
    left: 150px;
    @include themed() {
      color: t($text-color);
      border: 2px solid t($primary);
    }
    &:hover {
      @include themed() {
        background-color: t($primary-transparent);
      } 
    }
  }