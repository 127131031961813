
// strings for theme keys
$primary: 'primary';
$primary-alternate: 'primary-alternate';
$primary-transparent: 'primary-transparent';
$background: 'background';
$background-alternate: 'background-alternate';
$background-input: 'background-input';
$text-color: 'text-color';
$form-text-color: 'form-text-color';
$placeholder-color: 'placeholder-color';
$header-background: 'header-background';

$themes: (
    light: (
        primary: #7536C3,
        primary-alternate:#5E2B9C,
        primary-transparent: rgb(#7536C3, 0.5),
        background: rgb(248, 248, 248),
        background-alternate: #ebeaf2,
        background-input: #d9d6e0,
        text-color: #000,
        form-text-color: #646566,
        placeholder-color: rgb(0, 0, 0, 0.5),
        header-background: linear-gradient(90deg, rgba(11,0,118,1) 0%, rgba(117,54,195,1) 100%)
    ),
    dark: (
        primary: #8566C1,
        primary-alternate: #6947AE,
        primary-transparent: rgb(#8566C1, 0.5),
        background: #1B1D1F,
        background-alternate: #2A2D30,
        background-input: #404449,
        text-color: #FFF,
        form-text-color: #d2d3d4,
        placeholder-color: rgb(255, 255, 255, 0.5),
        header-background: #404449
    ),
);

@mixin themed() {
@each $theme, $map in $themes {
.theme-#{$theme} & {
  $theme-map: () !global;
  @each $key, $submap in $map {
    $value: map-get(map-get($themes, $theme), '#{$key}');
    $theme-map: map-merge($theme-map, ($key: $value)) !global;
  }
  @content;
  $theme-map: null !global;
}
}
}

@function t($key) {
@return map-get($theme-map, $key);
}