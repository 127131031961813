/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../../sass/abstracts' as *;

.admin {
    height: calc(100vh - 64px);
    width: 100%;
    padding: 15px;
    @include themed() {
        color: t($text-color);
        background-color: t($background);
    }
    .anchor {
        height: 100%;
        // width: 100%;
        position: relative;
    }
    &__content {
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 7.5px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 15px;
        border-radius: 10px 10px 0 0;
        @include themed() {
            background-color: t($background-alternate);
        }
    }
    &__permissions {
        display: grid;
        gap: 15px;
        flex: 1;
        overflow-y: auto;
        position: absolute;
        height: 100%;
        width: 100%;
        grid-template-areas: 
        'transaction details details'
        'results     notes   agency'
        'results     notes   agency'
        ;
    }

    .title {
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
    }


    .permission-group {
        border-radius: 0 0 10px 10px;
        @include themed() {
            background-color: t($background-alternate);
        }
        &__title {
            padding: 5px;
            text-transform: uppercase;
            font-weight: 600;
            @include themed() {
                color: white;
                background-color: t($primary);
            }
        }
    }

    .ant-collapse>.ant-collapse-item >.ant-collapse-header {
        padding: 0;
    }

    .group-select {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .ant-checkbox-group {
        display: flex;
        flex-direction: column;
      }

      .ant-collapse-content-box {
        display: flex;
        flex-wrap: wrap;
      }

      .ant-checkbox-wrapper+.ant-checkbox-wrapper {
        margin-inline-start: 0;
    }

}

.roles {
   
    overflow-y: auto;
    height: 100%;
    width: 100%;
   
   &-anchor { 
    // height: 100%;
    position: relative;
    width: 100%;
    border-radius: 10px;
    @include themed() {
        background-color: t($background-alternate);
    }
}

}