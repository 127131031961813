/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

.applicantDetails-body {
    width: 100%;
    height: 100%;

    .ant-tabs-nav-list {
        width: 150px;
    }

    .ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
    .ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
        padding: 0px;

        @include themed() {
            background-color: t($background);
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        @include themed() {
            color: t($text-color);
        }

        &:active {
            @include themed() {
                color: t($primary);
            }
        }
    }

    .ant-tabs-left>.ant-tabs-content-holder,
    .ant-tabs-left>div>.ant-tabs-content-holder {
        border: none;
    }

    .ant-tabs {
        height: 100%;

        &-tab {
            font-size: 1rem;
            margin: 0 !important;
            padding: 10px 10px !important;
            white-space: initial;
            justify-content: center;
            word-break: normal;
        }

        &-tab:hover {
            @include themed() {
                color: t($primary);
            }
        }

        &-tab-active {
            font-weight: bold;

            @include themed() {
                background-color: t($background);
            }

            &::before {
                content: '';
                position: absolute;
                width: 6px;
                height: 100%;
                left: 0;
                z-index: 2;

                @include themed() {
                    background-color: t($primary);
                }
            }
        }

        &-ink-bar {
            position: absolute;
            pointer-events: none;

            @include themed() {
                background-color: t($background);
            }
        }

        &-content-holder {
            position: relative;
        }

        &-content {
            position: relative;
            height: 100%;
            width: 100%;
        }
    }

}

.applicantDetails-body_tab-content,
.ant-tabs-tabpane {
    height: 100%;
    width: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    border-radius: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    @include themed() {
        background-color: t($background-alternate);
    }
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}