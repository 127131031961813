/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../../../sass/abstracts' as *;

.applicant-details-footer {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 15px;
    padding-right: 15px;
    align-items: center;
    box-shadow: 0px -15px 20px 0px rgb(0,0,0,0.1);
    z-index: 1;
    @include themed() {
      background-color: t($background-alternate);
    }
  }

  .ant-btn.save-button {
    background-color: #23BEB0;
    border: none;
    &:hover {
      background-color: #1a998f;
      color: white;
    }
    &[disabled] {
      background-color: gray;
    }
  }
