/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../../sass/abstracts/theme' as *;

.form-contianer{
    display: flex;
    flex-direction: column;
    gap: 15px;
    grid-column: 1/-1;
}

.labeled-column {
    display: grid;
    gap: 5px 15px;
    padding: 10px;
    border-radius: 0 0 18px 18px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1); 
    // 42px is height of purple title bar
    grid-template-columns: repeat(4, 1fr);
    height: calc(100% - 42px);
    @include themed() {
        background-color: t($background-alternate);
      }
}

.applicantsDetailsForm {
    padding: 10px;
    width: 100%;
    position: absolute;
    height: 100%;
    overflow-y: auto;
    @include themed() {
        background-color: t($background);
      }

    label {
        text-transform: uppercase;
    }

    .ant-form-vertical .ant-form-item .ant-form-item-control {
        border-radius: 5px;
        @include themed() {
            background-color: t($background-input);
          }
    }
    

    &-container {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
    }
    &_personal-info {
        grid-template-columns: repeat(4, 1fr);
    }
    &_transactional-information {
        grid-template-columns: repeat(4, 1fr);
    }
    &_status-history {
        grid-template-columns: 1fr;
    }
    &_adjudication-documents {
        grid-template-columns: 1fr;
    }


        &-title {
            grid-column: 1/-1;
        }

        &-buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-end;
            padding: 0.5rem 0.5rem 0 0;
            margin-top: 200px;
        }

        &-table {
            padding: 6px 10px;
        }
    }


.col-span-2 {
    grid-column: span 2;
}
.col-span-all {
    grid-column: 1/-1;
}

.ant-form {
    height: 100%;
}

.ant-input {
    @include themed() {
        background-color: t($background-input);
        color: t($form-text-color);
    }
}

.ant-input-suffix {
    @include themed() {
        background-color: t($background-input);
        color: t($text-color)
    }
}