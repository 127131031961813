/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use "../../../sass/abstracts" as *;

.results-container {
  height: 100%;
  width: 100%;
  display: grid;
  gap: 1px;
  // buttons | scroll
  grid-template-columns: 5fr 10fr;
  padding: 10px;
  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
}

.results-controls {
  display: grid;
  gap: 15px;
  height: calc(100% - 42px);
  width: 100%;
  padding: 20px;
  border-radius: 0 0 0 18px;
  @include themed() {
    background-color: t($background-alternate);
    color: t($text-color);
  }
}

.check-controls-container {
  display: flex;
  gap: 15px;
}

.result-check {
  color: white;
}

.ant-table-cell.ant-table-row-expand-icon-cell:not(th) {
  @include themed() {
    border-left: 1px solid t($primary-transparent);
  }
  &:has(.expanded-icon) {
    @include themed() {
      background-color: t($background-alternate);
      border-right: 1px solid t($primary);
    }
  }
}

.no-hits-indicator {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background-color: gray;
}

.column {
  display: flex;
  flex-direction: column;
}

.slider {
  display: grid;
  align-content: center;
  &__filter-button {
    width: 100%;
    border: none;
    // @include themed() {
    //     background-color: t($primary);
    // };
  }
}

.slider-container {
  display: flex;
  align-items: center;
  gap: 15px;

  &__eye-icon {
    font-size: 20px;
    @include themed() {
      color: t($text-color);
    }
  }

  .ant-slider-rail {
    @include themed() {
      background-color: t($background);
    }
  }
  .ant-slider-track {
    display: none;
    pointer-events: none;
  }
  .ant-slider-dot {
    @include themed() {
      border-color: t($primary);
      background-color: t($background);
    }
  }
}

.editor-overlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 5px;
  &__button {
    height: 40px;
    width: 80px;
    display: flex;
    align-items: center;
    padding: 10px;
    @include themed() {
      background-color: t($primary);
    }
  }
}

.editors-container {
  height: 100%;
  display: flex;
  width: 100%;
  position: relative;
  gap: 1px;

  &__editor {
    flex: 1;
    height: 100%;
    position: relative;
  }

  &__textarea {
    width: 100%;
    height: 100%;
    color: black;
    resize: none;
    padding: 5px 10px;
  }
  &__textarea-container {
    & > div:not([class], [id]) {
      height: 100%;
      position: relative;
    }
  }
}

.ProseMirror {
  width: 100%;
  height: calc(100% - 42px);
  color: black;
  resize: none;
  padding: 5px 10px;
  background-color: white;
  position: absolute !important;
  overflow-y: auto;
}

.fullscreen-button {
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 3;
  color: white;
  font-size: 20px;
}

.results-table-expanded-row {
  .expanded-row {
    height: 100%;
    width: 100%;
    @include themed() {
      background-color: t($background-alternate);
    }
    &::after {
      content: "";
      position: absolute;
      top: -1px;
      right: 0;
      width: 58px;
      height: 1px;
      @include themed() {
        background-color: t($background-alternate);
      }
    }
  }

  td {
    height: 200px;
    padding: 0 !important;
    @include themed() {
      border-left: 1px solid t($primary);
      border-right: 1px solid t($primary);
    }
  }
  .container-body {
    height: calc(100% - 42px);
    width: 100%;
    display: flex;
    padding: 10px;
  }
  .container-header {
    position: sticky;
    top: 0;
    width: calc(100% - 58px);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-context-menu {
  position: absolute;
  background-color: lightgray;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  @include themed {
    border: 1px solid t($primary);
  }
  & > div {
    color: black;
    font-size: 0.9rem;
    padding: 5px 10px;
    user-select: none;
    cursor: pointer;
    &:hover {
      background-color: darkgray;
    }
    &:not(:last-child) {
      @include themed {
        border-bottom: 1px solid t($primary);
      }
    }
  }
}

.result-temp-container {
  height: 100%;
  width: 100%;
  padding: 10px;
  .loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    @include themed() {
      background-color: t($background-alternate);
    }
  }
}
