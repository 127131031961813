/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

.loginContainer {
  width: 400px;
  height: 350px;
  margin: auto;
  border-radius: 15px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  background-color: #2a2d30;
  align-items: center;
  justify-content: space-evenly;
}

.sfwToken_mfaContainer {
  position: absolute;
  width: 400px;
  height: 400px;
  margin: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background-color: #2a2d30;
  align-items: center;
  justify-content: space-evenly;
}

.forgotPassword {
  cursor: pointer;
  color: grey;
}

.mfaContainer {
  position: absolute;
  width: auto;
  height: auto;
  margin: auto;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  background-color: #2a2d30;
  align-items: center;
  justify-content: space-evenly;
}

.mfaLabel {
  width: 100%;
  height: 50px;
  background-color: #111213;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mfaLabelText {
  font-weight: bold;
  color: white;
  margin-left: 10px;
}

.mfaContent {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 450px;
  width: 100%;
  padding-top: 1.5em
}

.sfwToken_mfaContent {
  height: calc(100% - 100px);
  width: 400px;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.mfaContentLeft {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  align-items: center;
}

.mfaContentRight {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100%;
  padding-right: 1.5em;
}

.mfaText {
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.swfToken_mfaText {
  color: white;
  margin-bottom: 20px;
  font-size: 1em;
}

.swfToken_mfaText_pass {
  color: white;
  font-size: 1em;
}

.swfToken_mfaText_pass_b {
  color: white;
  font-size: 1em;
  font-weight: bold;
}

.qrContainer {
  display: flex;
  height: 300px;
  width: 300px;
  background-color: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.mfaFooter {
  width: 100%;
  height: 50px;
  background-color: #111213;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footerBtns {
  width: 225px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
}

.loginLogo {
  float: left;
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  
}

.logoName {
  color: white;
  font-size: 0.75em;
}


.logoText {
  font-weight: bold;
  color: white;
}

.loginInputs {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 5px;
  width: 65%;
  height: 100px;
}

.loginInputs .error-message {
  color: red;
  text-align: center;
  font-size: 0.9rem;
}

.mfa-error-message {
  color: red;
  margin-bottom: 15px;
}

.loginInputs .ant-input-password-icon {
  color: white;
}

.login-button {
  background-color: #8566c1;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #363636;
  color: white;
  border: none;
}

.ant-btn-loading.login-button {
  background-color: #9b78e1;
  color: white;
  border: none;
}

.login-button:hover {
  background-color: #6b4f9e;
  color: white;
}

.ant-input-password {
  border-radius: 3px;
}