/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

.header {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    justify-content: space-between;
    padding-right: 20px;
}

.theme-toggle {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include themed() {
        color: t($primary);
    }
    &.ant-btn:focus {
        border: none;
        @include themed() {
            color: t($primary);
        }
    }
}

.theme-dark {
    .log-out {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
    }
}

.theme-light {

    .log-out,
    .theme-toggle {
        background-color: #fff;
        color: #5E2B9C;
    }
}

.logo-nav-container {
    display: flex;
    align-items: center;
    gap: 25px;
}

.nav-item {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: white;
    
    &--active {
        font-weight: 600;
        border-bottom:  2.5px solid white;
        @include themed() {
            border-color: t($primary);
        }
    }
}

.anticon-menu:hover {
    cursor: pointer
}