/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

.ant-form-item {
  margin: 5px;
}

.ant-input {
  @include themed() {
    background-color: t($background-input);
  }

  &:hover:not(:disabled) {
    @include themed() {
      color: t($primary);
    }
  }

  &:focus {
    border-color: #8566c1;
  }

  &-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    &:hover {border-color: #8566c1;}
    &:focus {border-color: #8566c1;}
  }

  &-show-count-suffix {
    color: #ffffff95;
  }

  &-suffix {
    color: lightgray;
  }
}
