/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

.applicantDetailsHeader {
    &-name {
        padding-block: 5px;
        color: #ffffff !important;
        font-size: 12px !important;
        text-align: center;
        margin-bottom: 0 !important;

        @include themed() {
            background-color: t($primary);
        }
    }

    &_container {
        display: grid;
        // image, attributes, empty space
        grid-template-columns: 2fr 16fr 4fr;
        flex-grow: 1;
        gap: 5%;
        padding-left: 160px;
        font-size: 12px;

        @include themed() {
            color: t($text-color);
        }
    }

    &-attributes {
        display: grid;
        // distance between attribute columns
        grid-template-columns: 4fr 3fr 6fr;
        max-height: 90%;
        align-items: center;
    }

    &-attribute {
        display: grid;
        // distance between label and value
        grid-template-columns: 6fr 16fr;
    }

}

.person-container {
    justify-self: start;
}

.attribute-label {
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 1rem;
}