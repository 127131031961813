/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../../sass/abstracts' as *;

$gap-between-title-and-search: 15px;
.transaction-summary-search-container {
    display: grid;
    grid-template-rows: 70% 30%;
}

.summary-search-section-container {
    padding: 15px;
    height: calc(100% - 36px);
    @include themed() {
        background-color: t($background-alternate);
      }
}
.search-and-button-container{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 5px;
}
.search-anchor {
    position: relative;
    height: 88%;
}
.search-criteria-container{
    overflow-y: auto;
    height: 100%;
    width: 100%;
    position: absolute;
}
.button-container {
    display: flex;
    height: 10%;
    gap: 5px;
}

.saved-searches {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    justify-items: start;
    align-items: center;
    gap: 15px;
    @include themed() {
        color: t($text-color);
      }
}
