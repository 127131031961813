/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../sass/abstracts' as *;

$nav-height: 64px;

.transaction-summary-container {
    display: grid;
    grid-template-columns: calc(20% - 74.5px) calc(80% - -54.5px);
    gap: 15px;
    padding: 15px;
    height: calc(100vh - $nav-height);
    @include themed() {
        background-color: t($background);
      }
}

.table-background {
  border-radius: 10px;
    @include themed() {
        background-color: t($background-alternate);
      }
}

.table-background--alt{
  border-radius: 10px;
  @include themed() {
      background-color: t($background);
    }
}