/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use './sass/abstracts' as *;

ul.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 10px 17px;
  border-radius: 0 0 10px 10px;
  @include themed() {
    border-top: 3px solid t($background);
    background-color: t($background-alternate);
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.selectFilterMode {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.modeSelectTextContainer {
  display: flex;
  height: 40px;
  align-items: center;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  padding: 5px;
  @include themed() {
    background-color: t($primary);
  }
}

span.filterModeText {
  text-align: center;
  margin: auto 0;
  width: 100%;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  @include themed() {
    color: t($text-color);
  }
}

.tableControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  @include themed() {
    border-bottom: 3px solid t($background);
    background-color: t($background-alternate);
  }
}

.logo {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}

.logoText {
  font-weight: bold;
  color: white;
  font-size: 1.8rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
