@use '../../sass/abstracts' as *;

.rapback-summary {
    height: calc(100vh - 64px);
    width: 100%;
    padding: 15px;
    @include themed() {
        background-color: t($background);
      }
}

.table-background {
    border-radius: 10px;
    @include themed() {
        background-color: t($background-alternate);
    }
}

.table-background--alt{
border-radius: 10px;
    @include themed() {
        background-color: t($background);
    }
}

.selectFilterMode {
    display: flex;
    flex-direction: row;
    text-align: left;
  }