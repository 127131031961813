/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../../sass/abstracts' as *;

.saved-searches-container {
    border-radius: 0 0 10px 10px;
    height: calc(100% - 36px);
    position: relative;
    @include themed() {
        background-color: t($background-alternate);
      }
    h2 {
        font-size: 1.25rem;
        font-weight: 500;
        @include themed() {
            color: t($text-color);
          }
    }
}

.saved-searches {
    font-size: 0.9em;
    
    .delete-search {
        text-decoration: underline;
        cursor: pointer;
    }
}

.saved-searches-items-container {
display: flex;
flex-direction: column;
padding: 15px;
gap: 10px;
position: absolute;
overflow-y: auto;
height: 100%;
}

.ant-modal-{
    &header {
    color: #fff;
    @include themed() {
        background-color: t($primary);
        border-color: t($primary);
      }
    }
    &body {
        padding: 20px;
    }
    &footer {
        padding: 10px;
        @include themed() {
            background-color: t($background);
            border-color: t($primary);
          }
    }
    &title {
        padding: 10px;
        color: #fff;
    }
    &content {
        padding: 0 !important;
    }
}


.ant-modal-confirm-body {
    .ant-modal-confirm- {
        &content, title {
            @include themed() {
                color: t($text-color);
              }
        }
    }
}

.ant-input-affix-wrapper{
    @include themed() {
        background-color: t($background-input);
      }
}