/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use '../../../sass/abstracts/' as *;

.notes-list {
    width: 30%;
    min-height: 100%;
    background-color: #212428;
    border-radius: 0 0 0 18px;
    overflow: hidden;
    @include themed() {
        background-color: t($background-alternate);
    }
    .ant-list-split .ant-list-header {
        border: none;
        padding: 0;
    }
}

.ant-list-item-meta-title {
    color: white;
    font-size: 1.1em;
}

.ant-list-item-meta-description {
    color: white;
    font-size: 0.8em;
    opacity: 80%;
    .theme-dark & {
        color: lightgray;
      }
      .theme-light & {
        color: black;
      }
}

.ant-list-split .ant-list-item {
    @include themed() {
        border-bottom: 1px solid t($primary);
    }
}
.ant-list-item {
    padding-inline: 5px;
}

.ant-list.ant-list-split {
    height: calc(100% - 42px);
    .ant-spin-container {
        height: 100%;
    }
    .ant-spin-nested-loading {
        height: 100%;
    }
}

.ant-list-header {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
}

.ant-list-items {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.note-list-header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    // border-top-left-radius: 5px;
    padding: 10px 20px;
    height: 42px;
    @include themed() {
        background-color: t($primary);
    }
}

.ant-list-item-meta-title {
    font-weight: bold;
    @include themed() {
        color: t($text-color);
    }
}

.notesHeader {
    font-size: 14px;
    font-weight: bold;
    color: white;
}

.headerText {
    color: white;
    font-weight: bold;
    font-size: 14px;
}

.new-note-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    gap: 5px;
    &--disabled {
        pointer-events: none;
        opacity: 50%;
    }
}

.note-editor {
    flex: 1;
    height: 100%;
    position: relative;
    textarea.ant-input {
        background-color: white;
        color: black;
        border-radius: 0;
        &:hover {color: black;}
    }
}

.ant-input-textarea-show-count::after {
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.note {
    &__button-container {
        display: none;
    }
    &--selected {
    @include themed() {
        background-color: t($background-input);
    }
    .note__button-container {
        display: flex;
        gap: 5px;
    }
    }
    padding: 10px !important;
}

.disabled-wrapper {
    cursor: not-allowed;
}