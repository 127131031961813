/*
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 *
 * Copyright: 2023 by Idemia Identity & Security USA LLC. All rights reserved.
 * License: In accordance  Idemia I&S USA LLC's license agreement.
 * Code Classification: GOVERNMENT
 *
 * Classification Person: Nadim Bakizada nadim.bakizada@us.idemia.com
 * Classification Reason: Software not specific to any U.S. Government Entity
 * Classification Date: 2023
 *
 * GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT - GOVERNMENT
 * IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA IDEMIA
 */

@use "./sass/abstracts" as *;
@import 'antd/dist/reset.css';

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

// first row bg color
tr:first-of-type th.ant-table-cell {
  @include themed() {
    border-bottom: t($background);
    background-color: t($primary) !important;
    color: #fff;
  }
  &:hover{
    @include themed() {
      background-color: t($primary-alternate);
    }
  }
}

.ant-table-body {
  @include themed() {
    border: 1px solid t($background-input);
  }
}

td.ant-table-cell.ant-table-cell-scrollbar {
  @include themed() {
    background-color: t($primary);
  }
}

.ant-table-column-title {
  font-weight: 500;
}

thead > tr > :is(th, td):not(.ant-table-cell-scrollbar){
  @include themed() {
    background-color: t($background-alternate) !important;
  }
}

.ant-table-thead > tr > :is(th, td) {
  border: none !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  @include themed() {
    background-color: t($background-alternate);
  }
}

.ant-table-thead > tr > th {
  font-weight: 500 !important;
}

.ant-layout-header {
  padding: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  z-index: 3;
  height: 64px;
  @include themed() {
    background: t($header-background);
  }
}

.ant-table-title {
  padding: 0 !important;
}

.ant-pagination-item {
  color: #fff;
  @include themed() {
    background-color: t($background-alternate);
  }
  &:hover {
    border-color: #fff;
    color: #fff;
  }
  &-active{
    @include themed() {
      background-color: t($primary);
    }
    a {color: #fff !important;}
}
}

.ant-pagination-total-text {
  margin: 0 10px;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-left {
  display: flex;
  & > .ant-pagination-total-text {
    order: 1;
  }
  & > .ant-pagination-options {
    order: 2;
  }
}

li.ant-pagination-options {
  margin: 0 !important;
  &::after {
    content: 'results per page';
    margin-left: 10px;
    opacity: 50%;
  }
  .theme-dark & {
    color: lightgray;
  }
  .theme-light & {
    color: black;
  }
}

.ant-picker.ant-picker-range.ant-picker-borderless {
  width: 100%;
  color: #fff;
  border-radius: 5px;
  @include themed() {
    background-color: t($background-input) !important;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  @include themed() {
    background-color: t($background-alternate);
  }
}

.container-header {
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  @include themed() {
    background-color: t($primary);
  }
}

.ant-btn-primary {
  box-shadow: 0 2px 0 rgb(0, 0, 0, 0.3);
}

.rc-virtual-list-holder-inner {
  gap: 2px;
}